@font-face {
  font-family: "MonCheri";
  src: url("../public/fonts/TAN\ -\ MON\ CHERI.woff2");
}

$DARK_BLUE: #00426d;
$DARK_BLUE_TEXT: #003153;
$DUSTY_BLUE: #73819c;
$TERRACOTTA: #b85657;
$PEACH: #c69ea1;
$CREAM: #f4eeea;
$CREAM_BACKGROUND: #f7f6f5;

$theme-colors: (
  "primary": $TERRACOTTA,
  "secondary": $PEACH,
);

$body-bg: $CREAM_BACKGROUND;
$body-color: $DARK_BLUE_TEXT;
$font-family-sans-serif: "Marcellus";

body {
  margin: 0;
  font-family: "Marcellus";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-weight: 200;
}

h3 {
  font-weight: 200;
  font-size: 16;
}

p {
  font-weight: 100;
  text-align: left;
}

// Import Bootstrap and its default variables.
@import "~bootstrap/scss/bootstrap.scss";

/** Override react bootstrap component behavior. */

/** Remove caret from dropdown menus. */
.dropdown-toggle::after {
  display: none;
}

/** Remove border on menu hamburger button. */
.navbar-toggler {
  border: none;
  box-shadow: none !important;
}

/** Registry button stuff. */
.registry-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.ywca-button:hover {
  opacity: .8;
}
